import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SectionTitleS2 from "../SectionTitleS2";
import { connect } from "react-redux";
import api, { promosApi } from "../../api";

const Promos = () => {
  const navigate = useNavigate();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const onViewRoom = (id) => {
    ClickHandler();
    navigate(`promos/${id}`);
  };

  const productsArray = promosApi();

  const products = productsArray;

  return (
    <div className="wpo-room-area section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-md-8">
            <SectionTitleS2 MainTitle={"Nuestras Mejores Promociones"} />
          </div>
        </div>
        <div className="room-wrap">
          <div className="row">
            {products.length > 0 &&
              products.slice(0, 4).map((product, pitem) => (
                <div
                  className={`${
                    products.length < 5 ? "col-lg-6" : "col-lg-4"
                  } col-md-6 col-12`}
                  key={pitem}
                >
                  <div className="room-item">
                    <div className="room-img">
                      <img src={product.cover} alt="" />
                    </div>
                    <div className="room-content">
                      <h2>
                        <Link
                          onClick={ClickHandler}
                          to={`/habitaciones/${product.id}`}
                        >
                          {product.title}
                        </Link>
                      </h2>
                      <ul>
                        <li>
                          <i className="fi flaticon-bed"></i>
                          {product.hours} Horas
                        </li>
                      </ul>
                      <p style={{ marginTop: "-10px" }}>
                        {product.shortDescription}
                      </p>
                      <h3>{product.price} Bs</h3>
                    </div>
                    <div className="add-to-cart">
                      <button
                        className="theme-btn mt-3"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="Add to Cart"
                        onClick={() => onViewRoom(product.id)}
                      >
                        Ver Promoción
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null)(Promos);
